﻿define("antiForgeryTokenHelper",
    ["jquery"],
    (function ($) {

            var antiForgeryTokenHelper = {

                //antiForgeryTokenHelper.AddAntiForgeryToken - Will append antiforgerytoken along with the given input
                //implementaion Example:
                //on Ajax call
                //before : data: { "destination": $("#txtNewUsernameValue").val(), "isEmail": isEmail },
                //after: data: antiForgeryTokenHelper.AddAntiForgeryToken({ "destination": $("#txtNewUsernameValue").val(), "isEmail": isEmail }),
                AddAntiForgeryToken: function (data) {

                    data.__RequestVerificationToken =
                        $("#__AjaxAntiForgeryForm input[name=__RequestVerificationTokenAjax]").val();
                    return data;
                },

                //retrives the AntiForgeryToken from the hidden field
                getAntiForgeryToken: function () {
                    var requestVerificationToken = $("#__AjaxAntiForgeryForm input[name=__RequestVerificationTokenAjax]");

                    return requestVerificationToken.length === 0 ? "" : requestVerificationToken.val();
                }
            };

            return antiForgeryTokenHelper;
        }
    )
);