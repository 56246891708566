﻿define("antiForgeryToken", ["jquery", "antiForgeryTokenHelper"],
    function ($, antiForgeryTokenHelper) {

        $(document).ready(function () {

            if (typeof antiForgeryTokenHelper !== "undefined") {
                // Will include antiForgeryToken as part of all request
                //__RequestVerificationToken is the name of the hidden field
                $.ajaxSetup({
                    headers: { 'RequestVerificationToken': antiForgeryTokenHelper.getAntiForgeryToken() }
                });
            }
        });
    });